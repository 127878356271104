import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React from "react"

export const HistoryIcon = ({ size = 25 }): JSX.Element => {
  const fill = useCSSCustomProperty("--shell-grey-colour", "#404040")

  return (
    <svg
      aria-hidden="true"
      width={size}
      height={size}
      viewBox="0 0 21 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.5C3 4.53 7.03 0.5 12 0.5C16.97 0.5 21 4.53 21 9.5C21 14.47 16.97 18.5 12 18.5C9.51 18.5 7.27 17.49 5.64 15.86L7.06 14.44C8.32 15.71 10.07 16.5 12 16.5C15.87 16.5 19 13.37 19 9.5C19 5.63 15.87 2.5 12 2.5C8.13 2.5 5 5.63 5 9.5H8L3.96 13.53L3.89 13.39L0 9.5H3ZM11 10.5V5.5H12.5V9.75L16 11.83L15.28 13.04L11 10.5Z"
        fill={fill}
      />
    </svg>
  )
}
