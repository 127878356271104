import { RefObject } from "react"
import { useSetUIPadding } from "@geome/recoil/dist/feature/map/hooks"
import useResizeObserver from "use-resize-observer"
import { useIsMobile } from "./useIsMobile"

export const useSidebarAdjustedUIPadding = ({
  sidebarRef,
  sidebarContentRef,
}: {
  sidebarRef: RefObject<HTMLElement>
  sidebarContentRef: RefObject<HTMLElement>
}): void => {
  const isMobile = useIsMobile()
  const setUIPadding = useSetUIPadding()

  useResizeObserver<HTMLElement>({
    ref: sidebarRef,
    onResize: (size) => {
      if (isMobile) {
        const contentHeight = sidebarContentRef.current?.getBoundingClientRect().height ?? 0
        const top = (size.height ?? 0) - contentHeight - getSidebarContentMargin(sidebarContentRef)
        setUIPadding({
          padding: { top, left: 0, right: 0, bottom: 0 },
          resizeMap: true,
        })
        return
      }

      const padding = { top: 0, left: size.width || 0, right: 0, bottom: 0 }
      const resizeMap = (size.height || 0) > window.innerHeight * 0.8
      setUIPadding({ padding, resizeMap })
    },
  })
}

const getSidebarContentMargin = (sidebarContentRef: RefObject<HTMLElement>): number => {
  const previousSibling = sidebarContentRef.current?.previousElementSibling
  if (!previousSibling) return 0
  const sidebarContentStartPoint = sidebarContentRef.current.getBoundingClientRect().y
  if (sidebarContentStartPoint === 0) return 0
  const previousSiblingRect = previousSibling.getBoundingClientRect()
  const previousSiblingEndPoint = previousSiblingRect.height + previousSiblingRect.y

  return sidebarContentStartPoint - previousSiblingEndPoint
}
