import React, { ReactElement } from "react"

export const EVLightningBoltIcon = (): ReactElement => (
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <path
      fill="none"
      stroke="currentColor"
      d="M11 1C5.479 1 1 5.479 1 11s4.479 10 10 10 10-4.479 10-10S16.521 1 11 1zm-.521 18.016v-6.273H6.992l5.01-9.759v6.273h3.357l-4.88 9.759z"
    />
  </svg>
)
