import React from "react"

export const MagnifyingGlassIcon = (): JSX.Element => (
  <svg
    aria-hidden="true"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.0254 10.1694L16.7126 16.8566" stroke="currentColor" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 6.47689C11.6667 9.4455 9.30236 11.8102 6.33333 11.8102C3.36431 11.8102 1 9.4455 1 6.47689C1 3.50786 3.36431 1.14355 6.33333 1.14355C9.30236 1.14355 11.6667 3.50786 11.6667 6.47689V6.47689Z"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
)
