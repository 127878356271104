import React, { ReactElement } from "react"

export const MapIcon = (): ReactElement => (
  <svg
    aria-hidden="true"
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.45 0.025L14.5833 0C14.8167 0 15 0.183333 15 0.416667V13.0167C15 13.2083 14.875 13.3583 14.7 13.4167L10 15L5 13.25L0.55 14.975L0.416667 15C0.183333 15 0 14.8167 0 14.5833V1.98333C0 1.79167 0.125 1.64167 0.3 1.58333L5 0L10 1.75L14.45 0.025ZM5 11.5748L10 13.3332V3.42484L5 1.6665V11.5748Z"
      fill="currentColor"
    />
  </svg>
)
