import React, { ReactElement } from "react"

export const TeacupIcon = (): ReactElement => (
  <svg
    aria-hidden="true"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8148 16.5H1.18519C0.780621 16.5 0.5 16.1963 0.5 15.8774V0.5H15.5V15.8774C15.5 16.1963 15.2194 16.5 14.8148 16.5Z"
      stroke="currentColor"
    />
    <rect x="1" y="19" width="14" height="1" fill="currentColor" />
    <path
      d="M15.5 3.52746C17.75 3.77619 19.5 5.68372 19.5 8C19.5 10.3163 17.75 12.2238 15.5 12.4725V3.52746Z"
      stroke="currentColor"
    />
  </svg>
)
