import React, { ReactElement } from "react"

export const ClockIcon = (): ReactElement => (
  <svg
    aria-hidden="true"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5 10C19.5 15.2464 15.245 19.5 10 19.5C4.75198 19.5 0.5 15.2465 0.5 10C0.5 4.75193 4.75193 0.5 10 0.5C15.245 0.5 19.5 4.75203 19.5 10Z"
      stroke="currentColor"
    />
    <path d="M10 5V10.2632L14.7368 15" stroke="currentColor" />
  </svg>
)
