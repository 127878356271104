import { useUIFilters } from "@geome/recoil/dist/feature/filters/hooks"
import React, { ReactElement, useCallback } from "react"
import { ScrollWithHeaderFooter } from "@geome/react-components-next/lib/components/scrollWithHeaderFooter"
import { FilterGroups } from "@geome/react-components-next/lib/components/filters/filterGroups"
import { useI18n } from "@geome/react-next/lib/hooks/i18n"
import { ButtonWithIcon } from "@geome/react-components-next/lib/components/buttonWithIcon"
import { useModalState } from "@geome/recoil/dist/feature/ui/hooks"
import { CloseIcon } from "../icons/close"
import { BlockScopingContextProvider } from "@geome/react-next/lib/context/blockScoping"
import { useSubscribeToUserInteraction } from "@geome/react-next/lib/hooks/userInteraction"
import { PumpIcon } from "../icons/pump"
import { ClockIcon } from "../icons/clock"
import { TeacupIcon } from "../icons/teacup"
import { EVLightningBoltIcon } from "../icons/evLightningBolt"

export const Filters = (): ReactElement => {
  const { toggle, groups } = useUIFilters()
  const { setCurrentModal } = useModalState()
  const { translate } = useI18n()

  const closeFilters = useCallback(async () => setCurrentModal(null), [setCurrentModal])
  useSubscribeToUserInteraction("*,-filters:*:*,-*:toggle-modal-button:*", closeFilters)

  return (
    <BlockScopingContextProvider block="filters">
      <ScrollWithHeaderFooter
        className="filters"
        renderHeader={() => (
          <div className="filters__title-bar">
            <h1 className="filters__title-bar-title">{translate("filters.title")}</h1>
            <ButtonWithIcon
              className="filters__close-button"
              text={translate("filters.close") as string}
              textPosition="tooltip"
              icon={<CloseIcon />}
              onClick={closeFilters}
              name="close-button"
            />
          </div>
        )}
      >
        <FilterGroups
          groups={groups}
          onToggleFilter={toggle}
          groupIcons={{
            amenities: <TeacupIcon />,
            fuels: <PumpIcon />,
            open_statuses: <ClockIcon />,
            ev_connector_types: <EVLightningBoltIcon />,
            ev_power: <EVLightningBoltIcon />,
          }}
          GroupHeadingTag="h2"
        />
      </ScrollWithHeaderFooter>
    </BlockScopingContextProvider>
  )
}
