import { useCSSCustomProperty } from "@geome/react-next/lib/hooks/useCSSCustomProperty"
import React, { ReactElement } from "react"

export const FunnelIcon = (): ReactElement => {
  const fill = useCSSCustomProperty("--shell-grey-colour", "#404040")

  return (
    <svg
      aria-hidden="true"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.04084 16.024C7.78043 16.024 7.56341 15.9406 7.3898 15.7736C7.21619 15.6067 7.12939 15.398 7.12939 15.1476V9.33834L1.16584 2.05168C0.974872 1.83467 0.94883 1.60514 1.08772 1.36308C1.22661 1.12103 1.42626 1 1.68668 1H15.6971C15.9575 1 16.1572 1.12103 16.2961 1.36308C16.4349 1.60514 16.4089 1.83467 16.2179 2.05168L10.2544 9.33834V15.1476C10.2544 15.398 10.1676 15.6067 9.99397 15.7736C9.82036 15.9406 9.60334 16.024 9.34293 16.024H8.04084ZM8.69189 9.41346L14.6554 2.1268H2.72834L8.69189 9.41346Z"
        fill={fill}
      />
    </svg>
  )
}
